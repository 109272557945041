import React, { useState, useEffect } from 'react';
import "../../style/HomeLive/index.scss";
import { useAtom } from "jotai";
import { DarkModeState,showsData,eventsAtom} from "../../Jotai/index";
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import { motion } from "framer-motion";

const HomeLive = (props) => {
    const [darkMode, setDarkMode] = useAtom(DarkModeState);
    const [VideoShow, setVideoShow] = useAtom(showsData);
    const [shows, setShows] = useAtom(eventsAtom);
    const [currentShow, setCurrentShow] = useState(null);
    const [nextShow, setNextShow] = useState(null);

    const getCurrentPacificTime = () => {
      // Convert current UTC time to Pacific Time
      return new Date(new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
    };
    useEffect(() => {
      const findShows = () => {
        let now = getCurrentPacificTime();
        let current = null;
        let next = null;

        for (let event of shows) {
          const startTime = new Date(event.start);
          const endTime = new Date(event.end);

          if (now >= startTime && now <= endTime) {
            current = event;
          } else if (now < startTime && (!next || startTime < new Date(next.start))) {
            next = event;
          }
        }
        setCurrentShow(current);
        setNextShow(next);
      };



      // Update shows every second
      const timerId = setInterval(() => {
        findShows();
      }, 1000);

      return () => clearInterval(timerId);
    }, [shows]);


  return (
    <>
      <div className="HomeLive">
        <div className="HomeLiveCard">
          <motion.div className={darkMode?"cardSection":"cardSection w_bg_color"} whileInView={{ scale: 1 ,transition: { duration: 0.6 } }} initial={{ scale: 0 }}>
            <div className="liveRadioTitle">
              <div className="liveRadioTitleRedbtn"></div>
              <div className="liveRadioTitleTxt">Live on Radio</div>
            </div>
            <div className={darkMode?"radioPlaylistNameactive":"radioPlaylistNameactive"}>
                {/* <div className="radioPlaylistName_nowplaying">Now Playing:</div> */}
                <div className="radioPlaylistName_name">{currentShow?currentShow.title : "No Shows right now"}</div>
                {/* <div className="radioPlaylistName_txt">{currentSong.artist!==""?currentSong.artist:""}</div> */}
                <div className="radioPlaylistName_time">{currentShow?currentShow.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }):""} - {currentShow?currentShow.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }):''}</div>
            </div>

            <div className="upNextTitle">Up Next...</div>
            <div className={darkMode?"radioPlaylistName":"radioPlaylistName"}>
                <div className="radioPlaylistName_name">{nextShow ? nextShow.title : "No show up next"}</div>
                {/* <div className="radioPlaylistName_txt"></div> */}
                <div className="radioPlaylistName_time">{nextShow ? (nextShow.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) +" - "+ nextShow.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })) : "No show up next"}{}</div>
            </div>
          </motion.div>
          <motion.div className={darkMode?"cardSection":"cardSection w_bg_color"}  whileInView={{ scale: 1 ,transition: { duration: 0.6 } }} initial={{ scale: 0 }}>
                <div className="newVideoSection">New Video</div>
                <div className="videoIframe">
               <iframe width={"100%"} height={"200px"} src={VideoShow[0].show} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />

                </div>
          </motion.div>
          <motion.div className={darkMode?"cardSection":"cardSection w_bg_color"}  whileInView={{ scale: 1,transition: { duration: 0.6 }  }} initial={{ scale: 0 }}>
            <div className="NewMagazine">New Magazine</div>
            <NavLink to={"https://issuu.com/22westmagazine"} className={"linkinmagazineSearch"} target="_blank" bis_size={"none"}>
            <img src="image/NewMagazine.png" className="NewMagazineimg" alt="NewMagazine" bis_size={"none"} style={{width:"100%"}}/>
            </NavLink>
            <div></div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default HomeLive;
