import React, { useEffect } from 'react';
import "../../style/Radio/index.scss"
import ScheduleCalendar from "../../components/ScheduleCalendar/ScheduleCalendar";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Herobanner from "../../components/herobanner/Herobanner";
import CardSmall from "../../components/Cards/cardSmall";
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import Sports from "../../components/Sports/Sports"
import Announcements from "../../components/Announcements/Announcements"
import Advertisements from "../../components/Advertisements/Advertisements";
import ShowsSlider from "../../components/ShowsSlider/ShowsSliders"
import ContactInfoRadio from "../../components/ContactInfoRadio/ContactInfoRadio"
const Radio = () => {
  const location = useLocation();

  const Data = [
    {
        title:"Radio",
        imageLink:"image/Radio/radioBanner.jpg",
        text:"22 West Radio, a student-run radio station at California State University, Long Beach, broadcasts on 88.1 FM HD3 and online. Featuring diverse programming, it involves over fifty students and community volunteers.",
        btnName:"none",
        btnLink:"/",
        btnColor:"#5F6F52",
        btnTextColor:"white"
    }
]
  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const scrollTo = queryParams.get('scrollTo');
    // If scrollTo parameter is present, scroll to the target element
    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        duration: 500,
        smooth: true,
        offset: -200,
      });
    }
  }, [location]);

  return (
    <>
    <ScrollToTop></ScrollToTop>
    <Herobanner imageData={Data}/>
    <Advertisements/>
    <ScheduleCalendar id="Radio_Schedule" />
    <Sports id="Radio_Sports" />
    
    {/* <Announcements /> */}
    {/* <ShowsSlider id="Radio_Shows"/> */}
    <ContactInfoRadio />
    </>
  );
};

export default Radio;
