import React, { useState } from "react";
import "../../style/AboutStaff/index.scss";
import { Link } from "react-router-dom";

const AboutStaff = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="AboutStaff"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? props.staffData.gif : props.staffData.img}
        alt="Hover Image"
        className="AboutStaffimg"
      />
      <div className="AboutStaffName">{props.staffData.name}</div>
      <div className="AboutStaffNamest">{props.staffData.status}</div>
      <div className="iconStr">
        {props.staffData.outline !== "" ? (
          <Link to={props.staffData.outline}>
            <img
              src="image/staff/bitcoin-icons_podcast-outline.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.dribble !== "" ? (
          <Link to={props.staffData.dribble}>
            <img
              src="image/staff/dribble.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.instagram !== "" ? (
          <Link to={props.staffData.instagram }>
            <img
              src="image/staff/instagram-line.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.linkedin !== "" ? (
          <Link to={props.staffData.linkedin }>
            <img
              src="image/staff/linkedin.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.tiktok !== "" ? (
          <Link to={props.staffData.tiktok}>
            <img
              src="image/staff/sharp-tiktok.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.soundcloud !== "" ? (
          <Link to={props.staffData.soundcloud }>
            <img
              src="image/staff/soundcloud-line.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.substack !== "" ? (
          <Link to={props.staffData.substack }>
            <img
              src="image/staff/substack.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.web !== "" ? (
          <Link to={props.staffData.web}>
            <img src="image/staff/web.svg" alt="icon" className="iconMain" />
          </Link>
        ) : (
          ""
        )}

        {props.staffData.youtube !== "" ? (
          <Link to={props.staffData.youtube}>
            <img
              src="image/staff/youtube-fill.svg"
              alt="icon"
              className="iconMain"
            />
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AboutStaff;
