import React from "react";
import "../../style/Home/index.scss"
import Navbar from "../../components/navbar/Navbar";
import Herobanner from "../../components/herobanner/Herobanner";
import LatestArticles from "../../components/LatestArticles/LatestArticles";
import HomeLive from "../../components/homeLive/HomeLive";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Advertisements from "../../components/Advertisements/Advertisements";
import WhatHappening from "../../components/WhatHappening/WhatHappening";
import EventsFetcher from "../../components/EventsFetcher/EventsFetcher";


const Data = [
    {
        title:"22 West Media",
        imageLink:"/image/Logo1.jpg",
        text:"Welcome to 22 West Media, the premier student-run digital media center at California State University, Long Beach. We encompass a production company, radio station, and magazine, providing a space for students to express themselves and gain hands-on experience in media production.",
        btnName:"none",
        btnLink:"/",
        btnColor:"#5F6F52",
        btnTextColor:"white"
    },
    {
        title:"Radio",
        imageLink:"image/Radio/radioBanner.jpg",
        text:"22 West Radio, a student-run radio station at California State University, Long Beach, broadcasts on 88.1 FM HD3 and online. Featuring diverse programming, it involves over fifty students and community volunteers.",
        btnName:"Go to show page",
        btnLink:"/radio",
        btnColor:"#3468C0",
        btnTextColor:"white"
    },
    {
        title:"Video",
        imageLink:"image/Video/videoBanner.jpg",
        text:"Founded in 2000, 22 West Video is a student-run broadcast outlet at California State University, Long Beach, that produces episodic and narrative content, fostering student engagement and creativity.",
        btnName:"Go to show page",
        btnLink:"/video",
        btnColor:"#86A7FC",
        btnTextColor:"white"
    }
    ,
    {
        title:"Magazine",
        imageLink:"/image/mgbanner.png",
        text:"22 West Magazine, a California State University, Long Beach staple since 1977, is a student-run and curated publication dedicated to serving and engaging the university community.",
        btnName:"Go to show page",
        btnLink:"/magazine",
        btnColor:"#FF9843",
        btnTextColor:"white"
    },
      {
        title:"Advertise with Us",
        imageLink:"image/About/AboutPageBanner.jpg",   
        text:"The LBSU campus is huge. Over 40,000 students, faculty and staff are part of our community, and since no outside publications can distribute on-campus, we offer the unique position to reach tens of thousands of potential customers in that all important demographic: college-age individuals 18-24 years of age. When you advertise with 22 West Media, you support students in media at Long Beach State University.", 
        btnName:"Go To Advertise Page",
        btnLink:"/advertise",
        btnColor:"#4cc8f4", 
        btnTextColor:"white"
      },

      {
        title:"About Us",
        imageLink: "image/Advertisements/22w-team-retreat-fa24.jpg",
        text:"22 West is a student-run, student-driven digital media center at California State University, Long Beach. We encompass a production company, radio station, and magazine, providing a space for students to express themselves and gain hands-on experience in media production. Any student with an interest in media is welcome to join the team, regardless of experience level. Visit us on the bottom floor of the USU to say hi!",
        btnName:"Go to about page", 
        btnLink:"/about",
        btnColor:"#4cc8f4", 
        btnTextColor:"white"
    }
]

const Home = () => {
  return (
    <>
    <ScrollToTop/>
    <EventsFetcher/>
    <div className="Homepage">
    <Herobanner imageData={Data}/>
    <Advertisements/>
    <HomeLive/>
    {/* <LatestArticles/> */}
    <WhatHappening/>
    </div>
   
    </>
  );
};

export default Home;
