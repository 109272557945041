import React from "react";
import "../../style/LatestVideo/index.scss";
import { DarkModeState ,showsData} from "../../Jotai/index";
import { useAtom } from "jotai";
import { useState } from "react";


const LatestVideo = ({id}) => {
  const [darkMode, setDarkMode] = useAtom(DarkModeState);
  const [shows, setshows] = useAtom(showsData);
  const [playData, setplayData] = useState(shows[0].show);

  return (
    <>
      <div id={id} className="LatestVideo">
        <div
          className={darkMode ? "LatestVideoMain darkmode" : "LatestVideoMain"}
        >
          <div className="LatestVideoTitle">Latest Videos</div>
          <div className="LatestVideoVideoSec">
            <div className="LatestVideoVideoSecleft">
              {shows.slice(0,3).map((data, index) => {
                return (
                  <>
                    <div
                    onClick={()=>{setplayData(data.show)}}
                      className={
                        darkMode
                          ? "LatestVideoCard"
                          : "LatestVideoCard wbgcolor"
                      }
                      key={index}
                    >
                      <div className="LatestVideoCard_img">
                        <iframe
                          src={data.show}
                          onClick={() => {}}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          className="LatestVideoVideoSecRight_imgVideo"
                        />
                      </div>
                      <div className="LatestVideoCard_data">
                        <div className="LatestVideoCard_data_title">
                          {data.name}
                        </div>
                        <div className="LatestVideoCard_data_name">
                          {data.title}
                        </div>
                        <div className="LatestVideoCard_data_Date">
                          {data.date_val}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="LatestVideoVideoSecRight">
              <iframe
                src={playData}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="LatestVideoVideoSecRight_video"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestVideo;
