import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";
import { NavLink } from "react-router-dom";
import "../../style/InfoCard/index.scss";

const InfoCard = ({id}) => {
    const [darkMode, setDarkMode] = useAtom(DarkModeState);
    return (
        <>
            <div id={id} className="InfoCard">
                <div className={darkMode ? "infoCard" : "infoCard w_bg_color"}>
                    <div className={darkMode ? "infoCardSection" : "infoCardSection w_bg_color"}>
                        <div className="infoCardTitle">Questions or Concerns?</div>
                        <div className="infoCardText">Email EIC Jensen Puckett <br />EditorInChief@22WestMedia.com</div>
                        <NavLink to="/archives" target="_blank" className="infoCardButton">EMAIL</NavLink>
                    </div>
                    <div className={darkMode ? "infoCardSection new_bg_color" : "infoCardSection new_bg_color w_bg_color"}>
                        <div className="infoCardTitle">Read archives of past issues!</div>
                        <div className="infoCardText"></div>
                        <NavLink to="/archives" target="_blank" className="infoCardButton">READ NOW</NavLink>
                    </div>
                    <div className={darkMode ? "infoCardSection" : "infoCardSection w_bg_color"}>
                        <div className="infoCardTitle">Volunteer Meetings</div>
                        <div className="infoCardText">Tuesdays 2 PM - 3 PM <br />For more updates, click here!</div>
                        <NavLink to="/archives" target="_blank" className="infoCardButton">INFO</NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoCard;