import React, { useState } from "react";
import "../../style/Advertisements/index.scss";
import { DarkModeState } from "../../Jotai/index";
import { useAtom } from "jotai";

const advertisementsData = [
 
  {
    image: "/image/Advertisements/Chemimatics_Button_Size_Ad_Dec2023.jpg",
    link: "https://www.chemimatics.com/",
    width: 448,
  },
  {
    image: "/image/Advertisements/classmates_new.png",
    link: "https://www.theclassmate.org/?utm_source=22westmedia&utm_campaign=22westmediawebad",
    width: 448,
  },
  {
    image: "/image/Advertisements/dj class- Steven_discord.jpg",
    link: "",
    width: 306,
  },
  
  {
    image: "/image/Advertisements/F22_22W_Welcome_22_dgtl-promo.jpg",
    link: "",
    width: 600,
  },

  {
    image: "/image/Advertisements/22_WEST_RADIO_FLYER_Light_Ver.png",
    link: "",
    width: 448,
  },
 
];

const Advertisements = (props) => {
  const [darkMode, setDarkMode] = useAtom(DarkModeState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };
  
  const renderAdvertisement = (ad, index, arrayLength) => {
    const adStyle = {
      width: `${ad.width}px`, // Set the width from the ad data
      height: '343px', // Keep the height fixed as before
      marginRight: index === arrayLength - 1 ? '0px' : '20px', // Adds a right margin except for the last item

    };
  
    if (ad.link) {
      return (
        <a href={ad.link} key={index} className="AdvertisementsLink" target="_blank" rel="noopener noreferrer" style={adStyle}>
          <img src={ad.image} alt="Advertisements" className="AdvertisementsImgData" />
        </a>
      );
    } else {
      return (
        <div key={index} className="AdvertisementsImgDataWrapper" style={adStyle} onClick={() => openModal(ad.image)}>
          <img src={ad.image} alt="Advertisements" className="AdvertisementsImgData canEnlarge" />
        </div>
      );
    }    
  };
  
  
  return (
    <>
      <div className="Advertisements">
        <div className={darkMode ? "AdvertisementsMain darkmode" : "AdvertisementsMain"}>
          <div className="Advertisements_title">Advertisements</div>
          <div className="Advertisements_horizontal">
            <a href='https://www.asicsulb.org/corporate/work' className="AdvertisementsLink" target="_blank" rel="noopener noreferrer">
              <img src='/image/Advertisements/22 West_Job-Banner_flyer.jpg' alt="22 West_Job-Banner_flyer" className="AdvertisementsHorizontalImgData" />
            </a>
          </div>
          <div className="MarqueeContainer">
            <div className="AdvertisementsImgSec">
             {advertisementsData.concat(advertisementsData).map((ad, index) => renderAdvertisement(ad, index, advertisementsData.length * 2))}
            </div>
          </div>
          {isModalOpen && (
            <div className="modal" onClick={() => setIsModalOpen(false)}>
              <img src={selectedImage} alt="Enlarged Advertisement" />
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default Advertisements;
