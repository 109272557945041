import React from "react";
import "../../style/herobanner/index.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

const Herobanner = (props) => {

  const isSingleImage = props.imageData.length === 1;

  return (
    <>
      <Carousel 
      showThumbs={false} 
      className="Herobanner" 
      emulateTouch={!isSingleImage}
      infiniteLoop={!isSingleImage} 
      preventMovementUntilSwipeScrollTolerance={!isSingleImage}
      showStatus={false}
      showIndicators={!isSingleImage}>
        {props.imageData.map((data) => {
          return (<>
            <div className="HerobannerCom">

              {/* Image Size 1929 * 828 px */}
              <div className={data.btnName !== "none" ? "HerobannerData" : "HerobannerData"}>
                <div className="HerobannerHeading">
                  {data.title}
                </div>
                <div className={data.btnName !== "none" ? "HerobannerText " : "HerobannerText"}>
                  {data.text}
                </div>
                {data.text2 && (
                  <>
                  <br/>
                   <div className={data.btnName !== "none" ? "HerobannerText " : "HerobannerText"}>
                  {data.text2}
                </div></>)}
                {data.btnName !== "none" ? <Link to={data.btnLink} style={{ textDecoration: "none", color: data.btnTextColor }} >
                  <div className="HerobannerBtn" style={{ background: data.btnColor }}>
                    {data.btnName}
                  </div>
                </Link> : ""}

              </div>
              <img src={data.imageLink} alt="1imag" className="imageTag" />

            </div>
          </>)
        })}


      </Carousel>

    </>
  );
};

export default Herobanner;
